.navbaricon {
    color: CornflowerBlue;
}

.link {
    font-weight: bold;
    cursor: pointer;
}

.imageHolder {
    display: flex;
    flex: 1;
    justify-content: center;
    align-content: center;
}

.image {
    padding-top: 16px;
    max-height: 500px;
    max-width: 500px;
    height: auto;
    width: auto;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
  
.containerMobile {
    height: 50vh !important;
}
  
.containerDesktop {
    height: 60vh !important;
}
  
.innerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 60%;
    gap: 20px;
}
  
.button {
    width: 20% !important;
    height: 100% !important;
    border-radius: 25px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 20px !important;
    text-transform: none !important;
}
  
.buttonDefault {
    background-color: #FFFFFF !important;
    color: #5078BB !important;
}
  
.buttonSelected {
    background-color: #5078BB !important;
    color: #FFFFFF !important;
}
  
.button:hover {
    background-color: #5078BB !important;
    color: #FFFFFF !important;
}
  
.imageContainer {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}
  
.mobileContainer {
    width: 80%;
    height: 80%;
    gap: 20px;
}
  
.mobileRow {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    height: 90%;
}
  
.mobileButton {
    width: calc(50% - 10px) !important;
    height: 90% !important;
    border-radius: 25px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px !important;
    text-transform: none !important;
}

@media (max-width: 800px) {
    .image {
      width: 100%;
    }
}