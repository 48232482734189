.navbar {
    display: flex;
    flex: 0 1;
    flex-direction: column;
    margin-top: 16px;
    width: 100%;
}

.navitem {
    display: flex;
    flex: 0 1;
    flex-direction: row;
    padding: 16px;
    gap: 16px;
    white-space: nowrap;
    align-items: center;
    color: #5078BB;
    font-size: 18px;
    font-weight: bold;
}

.navitem.active {
    color: #FFFFFF;
    background-color: #5078BB;
    border-radius: 0px 20px 20px 0px;
  }

.navitem:hover {
    cursor: pointer;
    color: #5078BB;
    background-color: rgba(0, 0, 0, 0.04);
}

.navbaricon {
    color: CornflowerBlue;
}

@media (max-width: 800px) {
    .navbar {
        position: absolute;
        top: 60px;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: aliceblue;
        z-index: 100;
    }
}