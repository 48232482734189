.container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .innerContainer {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 80%;
    gap: 20px;
  }
  
  .button {
    flex: 1 !important;
    min-width: 0 !important;
    border-radius: 14px  !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 10px !important;
    text-transform: none  !important;
    align-self: stretch !important;
  }
  
  .buttonDefault {
    background-color: #FFFFFF !important;
    color: #5078BB !important;
  }
  
  .buttonSelected {
    background-color: #5078BB !important;
    color: #FFFFFF !important;
  }
  
  .button:hover {
    background-color: #5078BB !important;
    color: #FFFFFF !important;
  }
  