:root {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    /**/
    margin: 0px;
    padding: 0px;
}

body{
    display: flex;
    flex: 1;
    /**/
    margin: 0px;
    padding: 0px;
    position: relative;
}

#root{
    display: flex;
    flex: 1;
}

html {
    font-family: Roboto, Helvetica, sans-serif;
  
    /* 👇️ or use Operating system default fonts 👇️ */
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif; */
  }
